<template>
    <div>
      <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
       <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card class="mx-auto elevation-2"
          align="center"
          outlined>
        <v-layout wrap justify-center class="pa-8 gradientCard" v-if="test">
          <v-flex xs12 text-right align-self-end>
            <!-- <deleteStudent style="cursor: pointer" /> -->
          </v-flex>
          <v-flex xs12 align-self-center v-if="test">
            <v-layout wrap justify-center v-if="test">
              <v-flex xs12 text-right>
                      <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                    </v-flex>
              <v-flex xs12 text-center>
                <v-avatar size="96px" radius="10px">
                  <img
                    src="@/assets/images/Ellipse23.png"
                    alt="John"
                    class="pa-1"
                  />
                </v-avatar>
              </v-flex>
              <v-flex xs12 pb-2 text-center>
                <span class="nssbold" v-if="test" style="font-size: 18px; color: #ffffff">
                  {{ test.name }}
                </span>
              </v-flex>
              <v-flex xs12  text-center>
                  <span
                    class="nsregular"
                    v-if="test"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Father's Name : {{ test.nameofFather }}
                  </span>
                </v-flex>
                 <v-flex xs12  text-center>
                  <span
                    class="nsregular"
                    v-if="test"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Mother's Name : {{ test.nameOfMother }}
                  </span>
                </v-flex>
                <v-flex xs12 pb-2 text-center>
                  <span
                    class="nsregular"
                    v-if="test"
                    style="font-size: 12px; color: #ffffff"
                  >
                    Contact : {{ test.parentContactNumber }}
                  </span>
                </v-flex>
              <v-flex xs12 text-center>
                <v-chip
                  elevation="2"
                  rounded
                  color="#766BC0"
                  class="white--text"
                  style="font-weight: 500; font-size: 14px"
                  v-if="test"
                >
                  <span v-if="test.Class">
                    {{ test.Class }}
                  </span>
                </v-chip>
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  >Adm. No.: {{ test.admissionNo }}</v-chip
                >
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  v-if="test.school"
                  >School: {{ test.school.SchoolName }}</v-chip
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
        <v-card
          class="mx-auto elevation-2 pa-6"
          align="center"
          outlined
        >

         <!--------------------------list------------------------>

          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
            <v-tab>Outpass({{outpassCount}})</v-tab>
            <v-tab>Leave ({{leaveCount}})</v-tab>
            <!-------------------------TAB1--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
                <v-flex xs12 v-if="outpass" px-0 px-md-6 pt-2>
                  <v-layout wrap pa-md-2 class="nsbold" style="font-size: 16px">
                    <v-flex xs12 pa-md-2 v-if="outpass.length > 0">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">No.</th>
                              <th class="text-left">Outpass.No.</th>
                              <th class="text-left">From Date</th>
                              <th class="text-left">To Date</th>
                              <th class="text-left">Status</th>
                              <th class="text-left">View</th>
                              <th class="text-left">Issued Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in outpass" :key="i">
                              <td>
                                <span v-if="page == 1">
                                  {{ i + 1 }}
                                </span>
                                <span v-else>
                                  {{ i + 1 + 20 * (page - 1) }}
                                </span>
                              </td>

                              <td>
                                <span v-if="item.outPassNo">
                                  {{ item.outPassNo }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item.issueStatus">
                                  {{ item.issueStatus }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item.fromDate">
                                  {{ formatDate(item.fromDate) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <span v-if="item.toDate">
                                  {{ formatDate(item.toDate) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                              <td>
                                <!---------------outpass dialogue------------------------>
                                <v-dialog
                                  v-model="item.dialog"
                                 
                                  max-width="590"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      style="cursor: pointer; color: #766bc0"
                                      >mdi-eye</v-icon
                                    >
                                  </template>
                                  <v-card>
                                    <v-layout wrap>
                                      <v-flex xs12 pa-4>
                                        <v-layout wrap>
                                          <v-flex xs12 sm6 text-sm-left>
                                            <span
                                              class="nsbold"
                                              style="font-size: 16px"
                                            >
                                              Admission No:
                                              {{ item.admissionNo }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 sm6 text-sm-right>
                                            <span
                                              class="nsbold"
                                              style="font-size: 16px"
                                            >
                                              Outpass No: {{ item.outPassNo }}
                                            </span>
                                          </v-flex>
                                        </v-layout>
                                        <v-divider></v-divider>
                                        <v-layout wrap pa-md-4>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Name:</b>
                                              {{ item.studentid.name }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b> Issue Date:</b>
                                              {{ item.create_date }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>From:</b>
                                              {{ formatDate(item.fromDate) }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Isssued By:</b>
                                              {{ item.issuingAuthority }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>To:</b>
                                              {{ formatDate(item.toDate) }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b> Status:</b>
                                              {{ item.issueStatus }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-left>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Purpose:</b> {{ item.purpose }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 md6 text-md-right>
                                            <span
                                              class="nsregular"
                                              style="font-size: 14px"
                                            >
                                              <b>Destination:</b>
                                              {{ item.destination }}
                                            </span>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
<!-- 
                                      <v-btn
                                        color="#766BC0"
                                        text
                                        @click="item.dialog =false"
                                      >
                                        Close
                                      </v-btn> -->
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <!--------------------------------------->

                              </td>
                              <td>
                                <span v-if="item.create_date">
                                  {{ formatDate(item.create_date) }}
                                </span>
                                <span v-else>NA</span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <v-flex v-else xs12 align-self-center text-center pa-2>
                      <span class="nsregular">Currently no record found!</span>
                    </v-flex>
                  </v-layout>
                  <v-pagination
                    small
                    color="#766BC0"
                    total-visible="7"
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <!-------------------------TAB2--------------------->
            <v-tab-item>
              <v-layout wrap justify-center>
            <v-flex xs12 v-if="leave" pt-2>
              <v-layout wrap py-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="leave.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <!-- <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th style="width:6%" class="text-left">Class</th> -->
                          <th style="width:17%" class="text-left">From Date</th>
                          <th style="width:17%" class="text-left">To Date</th>
                          <th class="text-left">Purpose</th>
                          <th class="text-left">Status</th>
                          <th style="width:15%" class="text-left">Extention Status<span >&nbsp;</span></th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in leave" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <!-- <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>{{ item.studentid.name }}</td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->
                          <td>
                            <span v-if="item.fromDate">
                              {{ formatDate(item.fromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.purpose">
                              {{ item.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <!-- <span v-if="item.leaveStatus == 'wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item.leaveStatus == 'Rejected'">
                              Rejected
                            </span>
                            <span v-else-if="item.leaveStatus == 'vcApproved'">
                              Vice Principal Approved
                            </span>
                            <span v-else-if="item.leaveStatus == 'Pending'">
                              Pending
                            </span> -->
                            <span v-if="item.leaveStatus">
                              {{ item.leaveStatus }}
                            </span>
                            <span v-else>-</span>
                            <!-- <span v-if="item.leaveExtended==true">
                    <v-img  height="20px" width="15px" contain src="./../../assets/images/acc.webp"></v-img>

                            </span> -->
                          </td>
                          <td  >
                            <v-layout wrap   class="text-right"  v-if="item.leaveExtended == true" >
                              <v-flex  pa-1 text-left>
                                <span>{{
                                  item.extensions[0].leaveStatus
                                }}</span>
                              </v-flex>
                              <v-flex text-left  pa-1 >
                                <span>
                                  <v-img 
                                    height="30px"
                                    width="25px"
                                    contain
                                    @click="extenddialoge=true , Extent = item.extensions    "
                                    src="./../../assets/images/icon2.png"
                                  ></v-img>
                                </span>
                              </v-flex>
                            </v-layout>
                            
                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <!-------------------dialogue box--------------------->
              <v-dialog width="1000px" v-model="extenddialoge">
                <v-card width="1000px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Extention List</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout>
                    <v-flex>
                      <v-simple-table class="px-2">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th>
                          <th class="text-left">Extended Date</th>
                          <th class="text-left">Purpose</th>
                          <th class="text-left">No.of Days</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Reason</th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in Extent" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                         
                          <td>
                            <span v-if="item.oldFromDate">
                              {{ formatDate(item.oldFromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.oldToDate">
                              {{ formatDate(item.oldToDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                           <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.purpose">
                              {{ item.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.numberOfDays">
                              {{ item.numberOfDays }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <!-- <span v-if="item.leaveStatus=='wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='vcApproved'">
                              VicePrincipal Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='Rejected'">
                           Rejected by :  {{item.rejectedBy.name}}
                            </span> -->
                            <span v-if="item.leaveStatus!='Rejected'">
                              {{ item.leaveStatus }}
                            </span>
                            <span v-else-if="item.leaveStatus=='Rejected'">
                           Rejected by :  {{item.rejectedBy.name}}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.leaveStatus=='Rejected'">
                            {{item.rejectReason}}
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="extenddialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!------------------------------------------>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>

    </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      test: null,
      leave: null,
      stData: null,
      extenddialoge: false,
      Extent:null,
    outpass: null,
      appLoading: false,
      ServerError: false,
   page: 1,
      Pagelength: 0,
      outpassCount: null,
      leaveCount: null,
    };
  },
  mounted() {
    this.getData();
    this.getOutpass();
    this.getLeave();
// this.getOutpass();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/per/student/leavehistory",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.outpass = response.data.data;
            this.stData = response.data.data;
            this.leaveCount = response.data.totalLength;
            this.test = response.data.studentdetails;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     getOutpass() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "get",
        url: "/outpasshistory/per/student",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;
            this.outpassCount = response.data.totalLength;
            for (let i = 0; i < this.outpass.length; i++) {
              if (this.outpass[i].issueStatus == "Active") {
                this.outpass[i].issueStatus = "Issued";
              }
            }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getLeave() {
      this.appLoading = true;

      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "get",
        url: "/per/student/leavehistory",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          studentid: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   keyword: this.keyword,
          //    from: this.fromDate,
          //   to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.leave = response.data.data;
            // for (let i = 0; i < this.outpass.length; i++) {
            //   if (this.outpass[i].issueStatus == "Active") {
            //     this.outpass[i].issueStatus = "Issued";
            //   }
            // }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
   formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.gradientCard {
  background: transparent
    linear-gradient(104deg, #434343 0%, #484848 52%, #2c2b2b 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
}
</style>